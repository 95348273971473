import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const FeatureContainer = styled(motion.div)`
  min-height: ${props => props.height || "15vh"};
  max-height: 100%;
  min-width: 70%;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0 1rem 1.05rem;
  justify-content: center;
  text-align: left;
  background-color: ${props => props.bgColor || "#1d1d1d"};
  p {
    // font-family: Tahoma, Geneva, Verdana, sans-serif;
  }
  button {
    // font-family: Tahoma, Geneva, Verdana, sans-serif;
  }
  @media (max-width: 300px) {
    width: 90%;
    min-width: 90%;
    background-color: var(--main-color);
  }
  @media (max-width: 768px) {
    width: 93%;
    min-width: 93%;
    background-color: var(--main-color);
  }
`

const Featured = ({ children, bgColor }) => {
  return (
    <>
      <FeatureContainer
        initial={{ opacity: 0 }}
        transition={{ delay: 0.3, duration: 1.5 }}
        animate={{ opacity: 1 }}
      >
        {children}
      </FeatureContainer>
    </>
  )
}

export default Featured
