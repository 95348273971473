import React from "react"

import { graphql, useStaticQuery, Link } from "gatsby"
import { FlexCenter, LinearTitle } from "../styles/elements.js"

import "normalize.css" // Note this

import Layout from "../components/layout.js"
import Featured from "../components/Featured"
import Seo from "../components/Seo"

import styled from "styled-components"

const BlockWrap = styled.div`
  width: 100%;
`

const Block = styled.div`
  margin: 0 auto;
  min-width: 70%;
  max-width: 70%;
  display: flex;
  padding: 10px 0px 10px 10px;
  a {
    display: flex;
    text-decoration: none;
    padding-left: 1%;
    color: white;
  }
  a div {
    padding-left: 5px;
  }
  @media (min-width: 200px) {
    min-width: 90%;
  }
`

const Categories = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCourse {
        edges {
          node {
            id
            author
            title
            category
            slug
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <Seo
          title="Discover different categories of Learning Resources"
          keywords="masterclass, discover, online learning, online, learning"
        />

        <Featured bgColor="rebeccapurple" height="50vh">
          <FlexCenter>
            <LinearTitle>Reviews by Category</LinearTitle>
          </FlexCenter>
          <>
            {data.allContentfulCourse.edges.map(edge => {
              return (
                <BlockWrap key={edge.node.id}>
                  <Block>
                    <Link to={`/courses/${edge.node.slug}/`}>
                      <div>{edge.node.category}</div>
                      <div> - </div>
                      <div>{edge.node.title}</div>
                    </Link>
                  </Block>
                </BlockWrap>
              )
            })}
          </>
        </Featured>
      </Layout>
    </>
  )
}

export default Categories
